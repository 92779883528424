

@font-face {
  font-display: swap;
  font-family: 'Berlin Sans FB';
  src: local('Berlin Sans FB Regular'), url('assets/fonts/Berlin Sans FB Regular.eot'), url('assets/fonts/Berlin Sans FB Regular.woff') format('woff'),
    url('assets/fonts/Berlin Sans FB Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Google Sans';
  src: local('Google Sans Regular'), local('GoogleSans-Regular'), url('assets/fonts/Google Sans 400.woff2') format('woff2'), url('assets/fonts/Google Sans 400.woff') format('woff');
}

@import '@angular/cdk/overlay-prebuilt.css';

.ngx-dialog-backdrop {
    background-color: rgba(0,0,0,.32)
}

.ngx-dialog-panel {
    display: block;
    padding: 24px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
    background: white;
    color: #000000de;
}


.input-field.invalid > .select-wrapper > input {
  border-bottom: 1px solid red;
  box-shadow: 0 1px 0 0 red;
}

.input-field>.select-wrapper+label {
  font-size: 1rem;
  position: absolute;
  top: 0;
}

.input-field>input:not([type]),
.input-field>input[type='text']:not(.browser-default),
.input-field>input[type='email']:not(.browser-default),
.input-field>input[type='time']:not(.browser-default),
.input-field>input[type='date']:not(.browser-default),
.input-field>input[type='datetime']:not(.browser-default),
.input-field>input[type='tel']:not(.browser-default),
.input-field>input[type='number']:not(.browser-default),
.input-field>input[type='password']:not(.browser-default),
.input-field>.select-wrapper>input[type='text']:not(.browser-default),
.input-field>textarea.materialize-textarea {
  padding-left: 10px;
  padding-right: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 3rem;
  font-family: inherit;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.87);
  width: calc(100% - 20px);
}

.input-field>textarea.materialize-textarea {
  width: 100%;
}

.input-field>input:not([type]):focus,
.input-field>input[type='text']:not(.browser-default):focus,
.input-field>input[type='email']:not(.browser-default):focus,
.input-field>input[type='time']:not(.browser-default):focus,
.input-field>input[type='date']:not(.browser-default):focus,
.input-field>input[type='datetime']:not(.browser-default):focus,
.input-field>input[type='tel']:not(.browser-default):focus,
.input-field>input[type='number']:not(.browser-default):focus,
.input-field>input[type='password']:not(.browser-default):focus,
.input-field>.select-wrapper:focus
.input-field>textarea.materialize-textarea:focus,
.input-field>input:not([type]):hover,
.input-field>input[type='text']:not(.browser-default):hover,
.input-field>input[type='email']:not(.browser-default):hover,
.input-field>input[type='time']:not(.browser-default):hover,
.input-field>input[type='date']:not(.browser-default):hover,
.input-field>input[type='datetime']:not(.browser-default):hover,
.input-field>input[type='tel']:not(.browser-default):hover,
.input-field>input[type='number']:not(.browser-default):hover,
.input-field>input[type='password']:not(.browser-default):hover,
.input-field>.select-wrapper:hover,
.input-field>textarea.materialize-textarea:hover {
  // background-color:rgba(0,0,0,.13);
  background-color:whitesmoke;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

html {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5 {
  padding-bottom: 0.2rem;
}

a:hover {
  cursor: pointer;
}

infans-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  font-family: 'Google Sans', Helvetica, arial, sans-serif;
}

infans-root .modal {
  max-height: 90vh;
  top: 5vh !important;
}

.btn,
h1,
h2,
h3,
h4,
h5,
h6,
.title,
.collapsible-header {
  font-family: 'Berlin Sans FB', Helvetica, arial, sans-serif;
}


.input-field>.select-wrapper+label:not(.active),
.input-field>label:not(.active) {
  left: 16px;
  color: rgba(0, 0, 0, 0.6);
}

main h1 {
  font-size: 2.92rem;
}

.mb-3 {
  margin-bottom: 3rem;
}

svg.icon {
  width: 24px;
  height: 24px;
  vertical-align: middle;
  fill: currentColor;
  pointer-events: none;
}

svg.icon.small {
  width: 14px;
  height: 14px;
}

button svg.icon {
  vertical-align: text-top;
}

main {
  flex: 1 0 auto;
  color: #7e7e7e;
  font-size: 16px;
  font-weight: normal;
}

footer {
  border-top: 1px solid #e3e3e3;
}

.brand-logo,
.brand-logo img {
  max-height: 64px;
  max-width: 100%;
}

.gradient-bg {
  background: linear-gradient(157deg, #fb542b, #d12da7);
}

.gradient {
  display: inline-block !important;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: -webkit-linear-gradient(157deg, #fb542b, #d12da7);
  color: #d12da7;
  color: gradient-color-at(#fb542b, #d12da7);
  -webkit-text-fill-color: transparent;
}

h2.gradient {
  margin-bottom: 3rem;
}

.info,
.warning,
.error {
  background: #fff;
  border: 1px solid #eee;
  border-left: 3px solid green;
  border-radius: 3px;
  padding: 1.25rem;
  margin: 1.25rem 0;
}

.warning {
  border-left: 3px solid orange;
}

.error {
  border-left: 3px solid red;
}

.providerLogo {
  max-height: 18px;
  margin-right: 10px;
  vertical-align: text-bottom;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.dropdown-content li>a,
.dropdown-content li>span {
  color: #333 !important;
}

.workshops a.square {
  display: flex;
  line-height: 64px;
  padding: 0 15px;
  height: 108px !important;
  flex-direction: column !important;
  align-items: center !important;
  outline: none;
}

.workshops a.square .icon {
  font-size: 48px;
  width: 48px;
  height: 48px;
  margin: 20px 0 -10px 0;
  padding: 0;
  display: block;
}